import axios from 'axios';

const axiosInstance = axios.create({
  withCredentials: true,
});
axiosInstance.defaults.params = {};

// needed to invalidate kinsta GET cache
axiosInstance.interceptors.request.use(config => {
  if (config.method === 'get') {
    // eslint-disable-next-line no-param-reassign
    config.params.v = Math.random()
      .toString(36)
      .slice(-10);
  }

  return config;
});
// axiosInstance.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error.response.status === 401) {
//       sessionStorage.clear();
//     }
//     return error;
//   },
// );

export default axiosInstance;
