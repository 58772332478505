import styles from './Footer.scss';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import React from 'react';
import Link from '@wienenergiegithub/ui-next/src/common/components/Link/Link';
import Content from '@wienenergiegithub/ui-next/src/common/components/Content/Content';
import FontAwesomeProIcon from '@wienenergiegithub/ui-next/src/common/components/FontAwesome';
import classNames from 'classnames';

const Footer = ({ socialMenu, footerMenuQuick, legalMenu }) => {
  return (
    <footer id="page-footer" className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <nav className={styles.metaMenuContainer} aria-label="Inhaltsangabe">
            <ul className={styles.metaMenu}>
              {footerMenuQuick &&
                footerMenuQuick.map(item => (
                  <li className={styles.metaGroup} key={item.url}>
                    <Link
                      to={item.url}
                      className={classNames(
                        {
                          [styles.quickTitle]: item.childItems,
                        },
                        styles.innerItem,
                      )}
                    >
                      {item.title}
                    </Link>
                    <ul className={styles.quickContainer}>
                      {item.childItems &&
                        item.childItems.map(child => (
                          <li key={child.url}>
                            <Link
                              to={child.url}
                              className={styles.innerItem}
                              aria-label={`${item.title} ${child.title}`}
                            >
                              {child.title}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
            </ul>
          </nav>
          <div className={styles.socialLegalWrapper}>
            <nav id="social-nav" aria-label="Social Media">
              <ul className={styles.socialMenu}>
                {socialMenu.map(item => {
                  const [type, icon] = item.title.split(' ');
                  return (
                    <li className={styles.socialMenuItem} key={item.url}>
                      <Link
                        rel="nofollow noopener"
                        target="_blank"
                        to={item.url}
                        className={styles.socialMenuLink}
                        data-purpose={icon}
                        aria-label={icon}
                      >
                        <FontAwesomeProIcon
                          fixedWidth
                          className={styles.socialMenuIcon}
                          type={type}
                          icon={icon}
                          role="presentation"
                        />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
            {legalMenu && (
              <nav id="legal-nav" aria-label="Legal Menu">
                <ul className={styles.footerLegalMenu}>
                  {legalMenu?.map(item => {
                    return (
                      <li className={styles.legalMenuItem} key={item.url}>
                        <Link to={item.url} className={styles.legalItem}>
                          {item.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Container>
      <div className={styles.legalWrapper}>
        <Container>
          <div className={styles.legal}>
            <Content as="span" size="regular" className={styles.copyright}>
              © {new Date().getFullYear()} Wien Energie GmbH | Wien Energie
              Vertrieb GmbH & Co KG
            </Content>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
