import api from 'api';
import create from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(
  persist(
    (set, get) => ({
      user: null,
      isLoggedIn: () => {
        return get().user != null;
      },
      resetPassword: async () => {
        await api.get('/backend/sanctum/csrf-cookie');

        await api.post('/backend/api/forgot-password', {
          email: get().user.email,
        });
      },
      setNewPassword: async (email, password, token) => {
        await api.get('/backend/sanctum/csrf-cookie');

        await api.post('/backend/api/reset-password', {
          email,
          password,
          password_confirmation: password,
          token,
        });
      },
      userCan: allowed => {
        if (!get().user) {
          return false;
        }
        return !!get().user.permissions.find(itm => itm.name === allowed);
      },
      login: async (email, password) => {
        try {
          await api.get('/backend/sanctum/csrf-cookie');

          await api.post('/backend/login', {
            email,
            password,
            remember: true,
          });
          const {
            data: { data },
          } = await api.get('/backend/api/user');
          set({
            user: data,
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      },
      logout: async () => {
        try {
          await api.post('/backend/api/logout');
          set({ user: null });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
    }),
    {
      name: 'userStorage',
      getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    },
  ),
);

export default useAuthStore;
