import styles from './Header.scss';
import React from 'react';
import Heading from '@wienenergiegithub/ui-next/src/common/components/Heading/Heading';
import Content from '@wienenergiegithub/ui-next/src/common/components/Content/Content';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Breadcrumbs, {
  BreadcrumbItem,
  SingleBreadcrumbItem,
} from '@wienenergiegithub/ui-next/src/common/components/Breadcrumbs/Breadcrumbs';
import FontAwesomeProIcon from '@wienenergiegithub/ui-next/src/common/components/FontAwesome/FontAwesomeProIcon';

export const HeaderItem = ({ linkComponent: Link, children, ...other }) => {
  return (
    <Link className={styles.headerItem} {...other}>
      {children}
    </Link>
  );
};

export const HeaderMetaItem = ({ linkComponent: Link, children, ...other }) => {
  return (
    <Link className={styles.metaNavItem} {...other}>
      {children}
    </Link>
  );
};
export const HeaderMetaItemIcon = ({ children, ...other }) => {
  return (
    <FontAwesomeProIcon className={styles.metaNavItemIcon} {...other}>
      {children}
    </FontAwesomeProIcon>
  );
};
const Header = ({
  title,
  subTitle,
  breadcrumbs,
  logo,
  linkComponent: Link,
  homeLink = '/',
  items,
  metaNav,
}) => {
  const hasContent = breadcrumbs || title || subTitle;

  return (
    <div className={styles.wrapper}>
      <Container className={styles.container}>
        <div className={styles.metaNav}>{metaNav}</div>
        <nav className={styles.nav}>
          <Link to={homeLink} className={styles.appLogo}>
            {logo && <img className={styles.logo} src={logo} alt={logo} />}
          </Link>
          {items && <div className={styles.navigationItems}>{items}</div>}
        </nav>
        {hasContent && (
          <div className={styles.content}>
            <Breadcrumbs theme="secondary">
              {breadcrumbs &&
                breadcrumbs.map(({ to, title: breadcrumbTitle }, idx) => {
                  return idx === 0 ? (
                    <SingleBreadcrumbItem to={to} key={to}>
                      {breadcrumbTitle}
                    </SingleBreadcrumbItem>
                  ) : (
                    <BreadcrumbItem to={to} key={to}>
                      {breadcrumbTitle}
                    </BreadcrumbItem>
                  );
                })}
            </Breadcrumbs>
            <Heading noSpacing>{title}</Heading>
            {subTitle && <Content>{subTitle}</Content>}
          </div>
        )}
      </Container>
    </div>
  );
};

export default Header;
