import logo from '../assets/icons/logo.svg';
import useAuthStore from '../store/useAuth';

import * as React from 'react';
import Header, {
  HeaderItem,
  HeaderMetaItem,
  HeaderMetaItemIcon,
} from '@wienenergiegithub/layout/src/common/components/Header/Header';
import { Link } from 'gatsby';

const SiteHeader = ({ breadcrumbs, title, subTitle }) => {
  const currUser = useAuthStore(state => state.user);
  const userCan = useAuthStore(state => state.userCan);
  return (
    <>
      <Header
        appName="Muellanlieferung"
        title={title}
        homeLink="/wochenplanung"
        subTitle={subTitle}
        metaNav={
          <>
            {currUser ? (
              <HeaderMetaItem linkComponent={Link} to="/profil">
                Account
              </HeaderMetaItem>
            ) : (
              <HeaderMetaItem linkComponent={Link} to="/login">
                <HeaderMetaItemIcon
                  type="regular"
                  icon="user"
                  role="presentation"
                />{' '}
                Login
              </HeaderMetaItem>
            )}
          </>
        }
        logo={logo}
        linkComponent={Link}
        breadcrumbs={breadcrumbs}
        items={
          <>
            {userCan('read dispo') && (
              <HeaderItem linkComponent={Link} to="/wochenplanung">
                Wochenplanung
              </HeaderItem>
            )}
            {userCan('read avp') && (
              <HeaderItem linkComponent={Link} to="/avp">
                AVP
              </HeaderItem>
            )}
            {userCan('read facility') && (
              <HeaderItem linkComponent={Link} to="/anlagen">
                Anlagen
              </HeaderItem>
            )}
            {userCan('read customer') && (
              <HeaderItem linkComponent={Link} to="/kunden">
                Kundendaten
              </HeaderItem>
            )}
            {userCan('read article') && (
              <HeaderItem linkComponent={Link} to="/artikel">
                MA48 Artikel
              </HeaderItem>
            )}
            {userCan('read user') && (
              <HeaderItem linkComponent={Link} to="/benutzer">
                Benutzerverwaltung
              </HeaderItem>
            )}
          </>
        }
      />
    </>
  );
};

export default SiteHeader;
