import SiteHeader from './siteHeader';

import useAuthStore from 'store/useAuth';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Footer from '@wienenergiegithub/layout/src/common/components/Footer/Footer';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const Layout = ({
  children,
  title,
  breadcrumbs,
  subTitle,
  guestAllowed = false,
}) => {
  const currentUser = useAuthStore(state => state.user);
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );
  useEffect(() => {
    if (!currentUser && !guestAllowed) {
      navigate('/login');
    }
  }, [currentUser]);
  return (
    <main style={{ height: '100vh' }}>
      <Helmet
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        defaultTitle={site.siteMetadata.title}
      >
        <script>
          {`window.Userback = window.Userback || {};
    Userback.access_token = '32318|66120|3LjMpeg0gwdUkIMeOPCwx92pM';
    (function(d) {
        var s = d.createElement('script');s.async = true;
        s.src = 'https://static.userback.io/widget/v1.js';
        (d.head || d.body).appendChild(s);
    })(document);`}
        </script>
        <title>{title}</title>
      </Helmet>
      <SiteHeader breadcrumbs={breadcrumbs} title={title} subTitle={subTitle} />
      <div style={{ minHeight: '100vh', marginBottom: '100px' }}>
        {children}
      </div>

      <Footer
        footerMenuQuick={[
          {
            url: 'https://www.wienenergie.at/privat/',
            id: 16956,
            page_id: 368,
            level: 1,
            isItemCurrent: false,
            title: 'Privat',
            isCurrent: false,
            target: '',
            childItems: [
              {
                url: 'https://www.wienenergie.at/privat/produkte/strom/',
                id: 16938,
                page_id: 375,
                level: 2,
                isItemCurrent: false,
                title: 'Strom',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/privat/produkte/erdgas/',
                id: 19598,
                page_id: 397,
                level: 2,
                isItemCurrent: false,
                title: 'Erdgas',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/privat/erleben/blog/',
                id: 19620,
                page_id: 472,
                level: 2,
                isItemCurrent: false,
                title: 'Blog',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/privat/erleben/',
                id: 19601,
                page_id: 464,
                level: 2,
                isItemCurrent: false,
                title: 'Erleben',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/privat/hilfe-und-kontakt/',
                id: 40682,
                page_id: 476,
                level: 2,
                isItemCurrent: false,
                title: 'Hilfe & Kontakt',
                isCurrent: false,
                target: '',
                childItems: [],
              },
            ],
          },
          {
            url: 'https://www.wienenergie.at/business/',
            id: 17472,
            page_id: 2023,
            level: 1,
            isItemCurrent: false,
            title: 'Business',
            isCurrent: false,
            target: '',
            childItems: [
              {
                url: 'https://www.wienenergie.at/business/produkte/strom/',
                id: 17501,
                page_id: 2301,
                level: 2,
                isItemCurrent: false,
                title: 'Strom',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/business/produkte/erdgas/',
                id: 17498,
                page_id: 2310,
                level: 2,
                isItemCurrent: false,
                title: 'Erdgas',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/business/produkte/kaelte/',
                id: 19619,
                page_id: 2316,
                level: 2,
                isItemCurrent: false,
                title: 'Kälte',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/business/produkte/waerme/',
                id: 19609,
                page_id: 2313,
                level: 2,
                isItemCurrent: false,
                title: 'Wärme',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/business/hilfe-und-kontakt/',
                id: 19607,
                page_id: 3934,
                level: 2,
                isItemCurrent: false,
                title: 'Hilfe & Kontakt',
                isCurrent: false,
                target: '',
                childItems: [],
              },
            ],
          },
          {
            url: 'https://www.wienenergie.at/ueber-uns/',
            id: 16942,
            page_id: 2025,
            level: 1,
            isItemCurrent: false,
            title: 'Über uns',
            isCurrent: false,
            target: '',
            childItems: [
              {
                url: 'https://www.wienenergie.at/ueber-uns/unternehmen/',
                id: 17505,
                page_id: 8311,
                level: 2,
                isItemCurrent: false,
                title: 'Unternehmen',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/ueber-uns/karriere/',
                id: 16943,
                page_id: 2339,
                level: 2,
                isItemCurrent: false,
                title: 'Karriere & Jobs',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url: 'https://www.wienenergie.at/ueber-uns/presse/',
                id: 31811,
                page_id: 3995,
                level: 2,
                isItemCurrent: false,
                title: 'Presse',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url:
                  'https://www.wienenergie.at/ueber-uns/unternehmen/energie-klimaschutz/',
                id: 19618,
                page_id: 4004,
                level: 2,
                isItemCurrent: false,
                title: 'Energie & Klimaschutz',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url:
                  'https://www.wienenergie.at/ueber-uns/unternehmen/verantwortung/',
                id: 19613,
                page_id: 3966,
                level: 2,
                isItemCurrent: false,
                title: 'Verantwortung',
                isCurrent: false,
                target: '',
                childItems: [],
              },
            ],
          },
          {
            url: '',
            id: 17502,
            page_id: 17502,
            level: 1,
            isItemCurrent: false,
            title: 'Links',
            isCurrent: false,
            target: '',
            childItems: [
              {
                url: 'https://meine.wienenergie.at/',
                id: 17506,
                page_id: 17506,
                level: 2,
                isItemCurrent: false,
                title: 'Meine Wien Energie',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url:
                  'https://www.wienenergie.at/ueber-uns/unternehmen/verantwortung/#schlichtungsstelle',
                id: 47282,
                page_id: 47282,
                level: 2,
                isItemCurrent: false,
                title: 'Schlichtungsstelle',
                isCurrent: false,
                target: '',
                childItems: [],
              },
              {
                url:
                  'https://www.wienenergie.at/ueber-uns/unternehmen/verantwortung/grundversorgung/',
                id: 103667,
                page_id: 103667,
                level: 2,
                isItemCurrent: false,
                title: 'Grundversorgung',
                isCurrent: false,
                target: '',
                childItems: [],
              },
            ],
          },
        ]}
        legalMenu={[
          {
            url: 'https://www.wienenergie.at/agbs/',
            title: 'AGBs',
          },
          {
            url: 'https://www.wienenergie.at/datenschutz/',
            title: 'Datenschutzerklärung der Wien Energie',
          },
          {
            url: 'https://www.wienenergie.at/impressum/',
            title: 'Impressum',
          },
        ]}
        socialMenu={[
          {
            url: 'https://de-de.facebook.com/WienEnergie',
            title: 'brand square-facebook',
          },
          {
            url: 'https://www.instagram.com/wienenergie',
            title: 'brand instagram',
          },
          {
            url: 'https://twitter.com/WienEnergie',
            title: 'brand twitter',
          },
          {
            url: 'https://www.youtube.com/channel/UCZ-9aVoV5NBECRoZoj0isIQ',
            title: 'brand youtube',
          },
          {
            url: 'https://www.wienenergie.at/privat/erleben/blog/',
            title: 'solid newspaper',
          },
          {
            url: 'https://www.linkedin.com/company/wien-energie-gmbh',
            title: 'brand linkedin',
          },
        ]}
      />
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
